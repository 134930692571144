// Universal link styles for desktop navbar, mobile nav menu
%nav-item-styles {
  color: #241F21;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 300ms;
  transition-property: opacity;
  min-width: max-content;
  white-space: nowrap;
  font-weight: 400;
  &:hover, &:focus {
      opacity: 80%;
  }
}

nav#main-nav {
    grid-template-rows: min-content 1fr;
    background-color: #E6E7E7;
    #topNavBar {
      &.fullyOpen {
        #nav-logo {
          height: 33px;
        }
      }
      #nav-logo {
        height: 37px;
      }

      // Animate the three line button to an X, vice versa
      // on mobile nav menu toggle
      #toggle-nav-btn {
        * {
          transition-property: translate, width;
          @apply duration-300 ease-in-out;
        }
        &.open {
          &::before {
            transform: translateY(0) rotate(45deg);
          }
          .middle-line {
            width:0;
          }
          &::after {
            transform: translateY(0) rotate(-45deg);
          }
        }
        &::before {
          @apply duration-300 ease-in-out;
          content: "";
          position: absolute;
          width: 28px;
          height: 2px;
          background-color: #241F21;
          transform: translateY(-8px);
        }
        &::after {
          @apply duration-300 ease-in-out;
          content: "";
          position: absolute;
          width: 28px;
          height: 2px;
          background-color: #241F21;
          transform: translateY(8px);
        }
      }
    }
    #nav-menu-desktop {
      a {
        @extend %nav-item-styles;
      }
    }

    #nav-menu-mobile {
      a {
        @extend %nav-item-styles;
      }
    // To initially open the mobile nav
    // menu on button click/toggle
      right: -100%;
      &.open {
        right: 0;
      }
    }
  }
  
// These allow for the subnav and subsubnav menus
// to display properly on hover of their parent on desktop
  #nav-menu-desktop {
    li {
      ul {
        display: none;
      }
    }
    li:hover > ul {
      display: grid;
    }
    li > ul li:hover > ul {
      display: grid;
      left: 100%;
    }

    .subnav-list, .subsubnav-list {
      --gap-size: 0.5rem;
      gap: var(--gap-size);
    }
    
    // ADDING ADDITIONAL HOVER SPACE TO SUBMENUS
    .subnav-list__list-item, .subsubnav-list__list-item {
      gap: var(--gap-size);
      padding-block: calc(.5*var(--gap-size));
      
      &.first-subnav-item {
        padding-block-start: calc(3.75 * var(--gap-size));
      }
      &.last-subnav-item {
        padding-block-end: calc(3.75 * var(--gap-size));
      }
    }

    .subnav-list__list-item-link,
    .subsubnav-list__list-item-link {
      display: block;
    }

    .subnav-list__list-item-link {
      display: block;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        // Subnavs are using the font-sm class, so using that in calcs:
        height: calc((2 * var(--gap-size)) + theme("fontSize.sm"));
        width: 300px; // MAGIC#: just to expand menu far enough on hover
        // background-color: green; // Uncomment this for debugging the hover space
        opacity: 0.3;
        top: calc(-2 * var(--gap-size));
      }
    }
  }

  #nav-menu-mobile {
    // Manages the display of all nav menu lists on mobile nav menu
    .list-wrapper {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-duration: 500ms;
      display: grid;
      place-content: center;
      height: 100%;
      overflow-y: auto; // allows for scrolling if the menu is longer than the screen height
      backface-visibility: hidden;
    }
    // These are the lists that are deep cloned to be displayed in 
    // the slideout menu when a parent is clicked
    .subnav-list[data-level], .subsubnav-list[data-level] {
      display: none;
    }
  }
