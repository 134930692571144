.homepage-slide {
    background-repeat: no-repeat;
    background-size: cover;
    > div {
        background: linear-gradient(#241f21 0%, rgba(108, 105, 106, 0) 100%);
    }
   
}

#homepage_slideshow {
    .carousel__dots {
        top: unset;
        bottom: 40px;
        li.carousel__dot {
            opacity: 1;
            &::after {
                opacity: 1;
                border: 1px solid white;
                background: transparent;
            }
            &.is-selected {
                
                &::after {
                    background: rgba(255, 255, 255, 0.55);
                }
            }
        }
    }
}