.news-banner__container {
    @screen lg {
        grid-template-columns: repeat(2, 1fr);
    }
}

.event__list {
    @screen lg {
        margin-top: 3rem;
    }
}