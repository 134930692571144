@use 'sass:color';

//---------------------------------------------
// Overriding third-party and theming styles
//---------------------------------------------

  //---------------------------------------------
  // Have to rewrite the .font-sans class that's defined in the
  // tailwind.config.js file because the Tailwind Elements plugin
  // seemingly overwrites that class within the @tailwind utilities import;
  //---------------------------------------------
  // @layer utilities {
  //   .font-sans {
  //     font-family: "Euclid Circular B", sans-serif; 
  //   }
  // }
  
//---------------------------------------------
// Freeform Pro responsive styling of columns overrides
// Specific to the Contact Form (city, state, zip)
//---------------------------------------------
form {
  .freeform-row {
    .freeform-column { 
      &.has-error {
        input, textarea, select {
          @apply #{border-red-900 !important}; 
          border: 2px solid;
        }
      }
    }
    .freeform-column-4 {
      width: 100%;
      @screen md {
        @apply w-1/3;
      }
    }
  }
}

//---------------------------------------------
// Override of Tailwind Forms Plugin form-input styles
//---------------------------------------------
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], 
[type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], 
[type='time'], [type='week'], [multiple], textarea, select { 
  --tw-ring-color: #{"rgb(var(--color-brand))"};
  --tw-outline-color: #{"rgb(var(--color-brand))"}; 
  outline-color: #{"rgb(var(--color-brand))"};
  border-color: transparent;
  border-width: 0;
  padding: 0;
  background-color: transparent;
  line-height: initial;
  font-size: inherit;
  &:focus, &:focus-visible, &:focus-within {
    --tw-ring-color: #{"rgb(var(--color-brand))"};
    --tw-ring-offset-width: 10px;
    outline-offset: 10px;
  }
}

//---------------------------------------------
// Restyling the 'clear input' X on inputs
//---------------------------------------------
input[type="search"] {
  &::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.965' height='15.965' viewBox='0 0 15.965 15.965'%3E%3Cg id='Group_663' data-name='Group 663' transform='translate(1.414 1.414)'%3E%3Cline id='Line_45' data-name='Line 45' x2='13.136' y2='13.136' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3Cline id='Line_46' data-name='Line 46' x1='13.136' y2='13.136' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");  
  background-size: 10px 10px;
  &:hover {
    cursor: pointer;
  }}
  &.text-brand {
    // filter: $brandFilter;
    &::-webkit-search-cancel-button {      
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.965' height='15.965' viewBox='0 0 15.965 15.965'%3E%3Cg id='Group_663' data-name='Group 663' transform='translate(1.414 1.414)'%3E%3Cline id='Line_45' data-name='Line 45' x2='13.136' y2='13.136' fill='none' stroke='%23497E91' stroke-linecap='round' stroke-width='2'/%3E%3Cline id='Line_46' data-name='Line 46' x1='13.136' y2='13.136' fill='none' stroke='%23497E91' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A") !important;  
    }    
  }
}

//---------------------------------------------
// Override Accordion JS styles
//---------------------------------------------
  .accordion-container {
    .ac {
      margin-top: unset;
      // border: none;
      background-color: transparent;
      box-sizing: border-box;
      .ac-header {
        padding: 20px 0;
        // margin: unset;
        // padding: unset;
        .ac-trigger {
          width: 100%;
          cursor: pointer;
          background-color: transparent;
          position: relative;
          text-transform: uppercase;
          border-color: #{"rgb(var(--color-body))"};
          @apply spacing-120 text-sm;
          font-weight: 600;
          text-transform: uppercase;
          @extend .spacing-100;
          @apply font-heading;
          display: block;
          border-bottom: 2px;

          padding: 0;

          &:hover, &:focus, &:focus-within, &:active {
            color: unset; // necessary because the number counter was changing on
            // activity states
          }
          .ac-title {
            color: #{"rgb(var(--color-brand))"};
            transition: opacity 300ms ease;
          }
          &:hover, &:focus, &:focus-within, &:active {
            .ac-title {
              opacity: .75;
            }
          }
          &::after {
            @apply font-heading text-2xl;
            font-weight: 100;
          }
        }
      }
      .ac-panel {
        .ac-text {
          font: inherit;
          color: unset;
          padding: 0 0 1.5rem 0;
          margin: unset;
          display: grid;
        }
      }
    }
  }

//---------------------------------------------
// Override Carousel default styles 
//---------------------------------------------
:root {
  --fancybox-accent-color: #{"rgb(var(--color-brand))"};
  --carousel-button-svg-stroke-width: 0.5px;
  --carousel-button-svg-width: 25px;
  --carousel-button-svg-height: 17px;
  --carousel-slide-width: 100%; 
}
.carousel {
  $parent: &;

  min-height: 50vh;
  // @screen 2xl {
  //   min-height: calc(100vh - var(--nav-height-2xl));
  // }
  &__viewport, &__track, &__slide {
    height: 100%;
  }
  &__slide {
    padding: 0;
  }
}

.carousel__button {
  .fancybox__nav & { 
    --carousel-button-svg-stroke-width: 0.5px;
  }
}

//---------------------------------------------
// Google Translate Plugin style overrides
//---------------------------------------------
.goog-te-gadget-simple, .google-te-gadget {
  background-color: transparent !important;
  height: min-content !important;
  border: none !important;
  @apply font-sans;
  img {
    display: none;
  }
  span {
    border-color: white !important;
  }
  a {
    display: flex;
    color: white !important;
    column-gap: 0.5rem;
    @apply text-lg;
    span {
      color: white !important;
    }
  }
}
