.product__intro-desc-and-slideshow {
    @screen lg {
        grid-template-columns: repeat(2, 1fr);
    }
    @screen xl {
        //height: calc(100dvh - var(--nav-height-lg)); 
        //height: calc(100vh - var(--nav-height-lg));
    }
}

//---------------------------------------------
// NOTE: The below widths are all based on the following
// product__desc_spec_dl-container widths (a single col
// within this section). Keep in mind that the values
// are custom based on the existing margin values on the wrapping
// container!!
// SEE: /products/index template, /products/product-intro-desc-and-slideshow.scss
//---------------------------------------------
.product__intro-desc-container {
    @screen lg {
        // margin-left: auto !important;
        margin-inline: auto !important;
        padding-left: 0;

        width: calc(theme('screens.lg') * 0.5 - theme('container.padding.lg'));
        // padding-right: calc(theme('gap.28') * 0.5);
    }
    @screen xl {
        width: calc(theme('screens.xl')*0.5 - theme('container.padding.xl'));
        // padding-right: calc(theme('gap.44') * 0.5);
    }
    @screen 2xl {
        width: calc(theme('screens.2xl')*0.5 - theme('container.padding.2xl'));
        // padding-right: calc(theme('gap.60') * 0.5);
    }
}

.carousel {
    &.product__intro-slideshow {
        // height: 75vh;
        @screen lg {
            height: auto;
        }
        @screen xl {
            //min-height: calc(100dvh - var(--nav-height-lg));
            //min-height: calc(100vh - var(--nav-height-lg));
        }
    }
    // .carousel__nav {
    //     display: none;
    // }
    .carousel__dots {
        top: unset;
        bottom: 0;
        li.carousel__dot {
            
            &::after {
                border: 1px solid rgba(36, 31, 33, 0.62);
                background: transparent;
            }
            &.is-selected {
                
                &::after {
                    background: rgba(36, 31, 33, 0.27);
                }
            }
        }
    }
    .product__intro-slideshow-slide {
        //background-color: rgba(221, 221, 221, 0.522);
    }
}