.dealer-locator__search-and-results {
    .dealer-locator__search-wrapper {
        background-color: #{'rgba(var(--color-neutral) / 0.52)'};
        select {
            --tw-ring-offset-width: 0px;
            outline-offset: 0px;
        }
    }
}
.dealer-cat__title {
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #{'rgb(var(--color-grey))'};
    }
}
#dealer-results-list {
    // gap: 3rem;
    @screen sm {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @screen lg {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}