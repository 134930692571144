  //---------------------------------------------
  // COLORS - update in Wygwam Configurations, TailwindCSS Config file!
  //-------------------- -------------------------
  :root, ::before, ::after {

    --color-brand: 0 155 223; //#009BDF 
    --color-body: 36 31 33; //#241F21
    --color-grey: 112 112 112; //#707070
    --color-neutral: 212 227 224; //#D4E3E0 -- MOST LIKELY USING AN OPACITY OF THIS COLOR
    
    --logo-bg-grey: 230 231 231; //#e6e7e7

    //---------------------------------------------
    // Background Gradient as a placeholder to be @extend -ed
    //---------------------------------------------
    %background-gradient {
      background: linear-gradient(180deg, rgba(0,155,223,1) 0%, rgba(0,78,112,1) 100%);
    } 

    --fw-light: 300;
    // UPDATE: Noticed that fonts render slightly heavier than design, so updated from 400 to 300 
    // UPDATE 2: I changed it back to 400 from what Melissa did. -DB
    --fw-regular: 400; 
    --fw-bold: 700;
    --fw-black: 900;

    //---------------------------------------------  
    // From Kevin Powell: .flow class
    // Use the .flow class on a parent element where there's many children (like a parent for Wysiwyg)
    // to space each out evenly
    //--------------------------------------------- 
    --flow-spacer: 1.25rem;

    // Used to calculate heights of some areas (product intro area on detail page)
    // to remove nav's fixed height
    --nav-height-lg: 100px;
  }

  $brochure-icon-path: url('/images/svgs-details/brochure-icon.svg');
  $video-icon-path: url('/images/svgs-details/video-icon.svg');

    //---------------------------------------------
  // FONTS
  //---------------------------------------------
  // Define any TailwindCSS/Forms plugin styles within the base layer
  // ** Be sure to test font imports in various browsers

  //---------------------------------------------
  // IMPORT GOOGLE FONTS
  //---------------------------------------------
  @import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;500;600&display=swap');;

  //---------------------------------------------
  // IMPORT ADOBE FONTS
  //---------------------------------------------
  @import url("https://use.typekit.net/rvn1mfz.css");


  body .wrapper, body {
    font-family: neuzeit-grotesk,sans-serif;
    font-weight: #{'var(--fw-regular)'};
    font-style: normal;
    color: #{'rgb(var(--color-body))'};

    @mixin btn-styles($font-size: 0.625em) {
      font-family: 'Jura', sans-serif;
      text-transform: uppercase;
      @extend .spacing-100;
      font-size: $font-size;
      font-weight: #{"var(--fw-bold)"};
      padding: 1em 1.5em;
      width: fit-content;
    }
    @mixin btn-styles-animations($textColor) {
      transition-property: transform, border-color, background-color;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      cursor: pointer;
      &:hover, &:focus, &:focus-visible, &:active {
        background-color: $textColor;
        @if ($textColor == white) {
          color: #{'rgb(var(--color-brand))'};
        } @else {
          color: white; 
        }
      }
    }

    .btn-brand {
      @include btn-styles();
      @include btn-styles-animations(#{'rgb(var(--color-brand))'});
      border: 1px solid #{'rgb(var(--color-brand))'};
      
      &.font-xs {
        @include btn-styles(0.75em);
      }
      &.inverted {
        color: white;
        @include btn-styles-animations(white);
        border: 1px solid white;
      }

    }

    // EX: see the Products page - Additional Products & Accessories listings
    // The item contains a button, but we want the link to apply to the entire
    // item, not just the btn within it
    .stretch-btn-to-cover-item {
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
      }
    }

    // .select-wrapper {
    //   cursor: pointer;
    //   .select-otter {
    //     @extend .btn-styles;
    //     @apply grid grid-cols-2;
    //     @apply bg-none; // removes the default select arrow dropdown
    //     @apply border-otter text-otter border-2;
    //     @apply hover:border-otter hover:text-otter;
    //     @apply active:border-otter active:text-otter;
    //     @apply focus:border-otter focus:text-otter;
    //   }
    //   &::after {
    //     // Utilize URL encoder: https://yoksel.github.io/url-encoder/
    //     // content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.2' height='25' viewBox='0 0 17.2 25'%3E%3Cpath id='Previous_arrow' data-name='Previous arrow' d='M12.5,0,25,17.2H0Z' transform='translate(0 25) rotate(-90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
    //     content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='13' viewBox='0 0 9.2 13'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
    //     position: relative;
    //     right: 20px;
    //   }
    // }
  }

  .main {
    padding-top: 80px;
    @screen lg {
      padding-top: 70px;
      //min-height: calc(100vh - var(--nav-height-lg));
    }
  }

//---------------------------------------------
// WIDTHS
//---------------------------------------------
// w-3/12
// w-2/6
// w-6/12
// w-9/12
// w-screen


//---------------------------------------------
// PREFERS REDUCED MOTION
//---------------------------------------------
@media screen and (prefers-reduced-motion: reduce){
  *:not(#cover) {  
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}

//---------------------------------------------
// FILTERS
//---------------------------------------------
// Filters SVG from black to brand color #009BDF
$brandFilter: brightness(0) saturate(100%) invert(45%) sepia(34%) saturate(5117%) hue-rotate(171deg) brightness(98%) contrast(101%);


a, button {
  @apply cursor-pointer;
  &.in-text-link { 
    color: #{"rgb(var(--color-brand))"};
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
    transition-duration: 300ms;
    &:hover, &:focus, &:focus-visible, &:active {
      opacity: 0.75;
    }
  }
  &:not([class]):not([data-fancybox]) {
    @extend .in-text-link;
  }
}

  //---------------------------------------------
  // From Kevin Powell: .flow class
  // Use the .flow class on a parent element where there's many children (like a parent for Wysiwyg)
  // to space each out evenly
  //---------------------------------------------
.flow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1rem);
}


//---------------------------------------------
// Unordered and ordered list default styles
//---------------------------------------------
ul:not(.accordion):not(.accordion-collapse):not(.accordion-filter-list):not([role="navigation"]):not(.list-check):not(.list-results):not(.list-filters):not([role="listbox"]):not(.errors.help-block):not(.nav-tabs):not(.list-none),
ol:not(.accordion):not(.accordion-collapse):not(.accordion-filter-list):not([role="navigation"]):not(.list-check):not(.list-results):not(.list-filters):not([role="listbox"]):not(.errors.help-block):not(.nav-tabs):not(.list-none) { 
  list-style: disc;
  //list-style-position: inside;
  list-style-position: outside;
  margin-left: 1rem;
}

//---------------------------------------------
// When hovering over linked images (so an img within an <a>)
//---------------------------------------------
:is(a.grow-on-hover) img {
  @screen md {
    @apply duration-300 transition-transform;
    &:hover {
      @apply scale-110;
    }
  }
}

//---------------------------------------------
// Add margin above buttons when preceded by <p>
//---------------------------------------------
p ~ p:has(.btn-brand) {
  margin-top: 4rem;
}

