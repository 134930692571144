
$productsLandingTransitionSpeed: 300ms;

#product-cats__list-container {

    @media (min-resolution: 150dpi) {
        grid-gap: 0.5px !important;
    }

    @screen lg {
        grid-template-columns: repeat(var(--grid-columns), 1fr);
    }
    .category__item {
        //min-height: 500px;
        transition: min-height $productsLandingTransitionSpeed linear;
        @extend %brand-gradient;
        //background-color: rgba(36,31,33,0.8);
        .category__item-img-container {
            opacity: 0;
            // img {
            //     transition-property: opacity;
            //     transition-duration: $productsLandingTransitionSpeed;
            //     transition-timing-function: ease;
            //     opacity: 0;
            // }
            &::after {
                content: '';
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                @extend %body-gradient;
                z-index: 1;
            }
        }
        .category__text-content {
            hyphens: auto;
        }
        .category__products {
            transition: max-height $productsLandingTransitionSpeed ease;  
            overflow: hidden;
            // "height: 0" not work with css transitions
            max-height: 0;
            .category__products-description {
                // &::after {
                //     transition-property: opacity;
                //     transition-duration: $productsLandingTransitionSpeed;
                //     transition-timing-function: ease;
                //     opacity: 0;
                //     content: '';
                //     width: 150px;
                //     height: 1px;
                //     background: white;
                //     display: block;
                //     position: relative;
                //     margin: 0 auto;
                // }
            }
        }
        &:hover, &:active, &:focus {
            //min-height: 25vh;
            .category__item-img-container {
                opacity: 1;
                // img {
                //     opacity: 1;
                // }
            }
            .category__products {
                max-height: 100vh;
                .category__products-description {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        @screen xl {
            &:hover {
                .category__products {
                    max-height: 1000px;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

    }
}


