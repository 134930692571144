// Used on Homepage, Product Category landing pages now
.banner-gradient {
  background: linear-gradient(180deg, rgba(36,31,33,0.9513900560224089) 0%, rgba(36,31,33,0.25) 100%);
}

#about-banner {
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: #{'rgba(var(--color-body) / 0.7)'};
    z-index: 0;
  }
  .about-banner__secondary-image-wrapper {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 55%; // added extra 5% so there's no line of separation with the 'exact' %
      width: 100%;
      background-color: #{'rgba(var(--color-body))'};
      z-index: 0;
    }
  }
}

#contact-banner {
  .contact-banner__secondary-image-wrapper {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0; 
      height: 75%;
      width: 100%;
      z-index: 0;
    }
  }
}

.contact-banner__secondary-tagline, 
.contact-banner__secondary-image-wrapper::after {
  background-color: #D4E3E0;
}

