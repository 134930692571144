[data-block-name="features_row"] {
    background-color: #{"rgba(var(--color-neutral), 0.52)"};
    .features__items-title {
        @screen sm {
            grid-template-columns: repeat(2, max-content);
        }
        @extend .spacing-100;
        // &::before {
        //     content: '';
        //     position: relative;
        //     width: 50px;
        //     height: 1px;
        //     background-color: #{"rgb(var(--color-brand))"};
        // }
    }
    .features__items-content-container {
        .features__items-container {
            @screen md {
                grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
            }
            .feature__item-channel {
                color: #{"rgb(var(--color-grey))"};
                transition-property: color;
                transition-duration: 300ms;
                transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
                &:hover, &:active, &:focus, &:focus-within {
                    color: #{"rgb(var(--color-brand))"}
                }
            }
            [data-block-name="feature_item"] {
                .feature_item__image {
                    aspect-ratio: 1 / 1.15;
                    @screen md {
                        min-height: 375px;
                    }
                }
                a:has(img) {
                    overflow: hidden;
                    transition-property: transform;
                    transition-duration: 300ms;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    &:hover, &:active, &:focus, &:focus-within {
                        //transform: scale(1.02);
                    }
                }
            }
        }
    }
}