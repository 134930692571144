// NOTE: all within media query!!
// Using magic #s to style the two columns at lg
@screen lg {
    $contact-info-col-gap-lg: 4rem; // ** magic #
    .contact-form-contact-info__container, 
    .contact-more-info__container {
        grid-template-columns: repeat(2, 0.5fr);
        column-gap: $contact-info-col-gap-lg;
    }
    // .contact-form__container, 
    // .office-team__container {
    //     width: 75%; // ** magic #
    // }
    // .contact-info__container {
    //     padding-left: 15%;
    //     &::before {
    //         content: '';
    //         width: 1px;
    //         background-color: #{'rgb(var(--color-grey))'};
    //         height: 100%;
    //         position: absolute;
    //         inset: 0;
    //         left: calc($contact-info-col-gap-lg / -2);
    //     }
    // }
}

.two-col-contact-list {
    .contact__list-item, .contact__link {
        grid-template-columns: 1fr 2fr;
        column-gap: 1rem;
        &.team-item {
            grid-template-columns: unset;
            @screen sm {
                grid-template-columns: 1fr 2fr;
            }
        }
    }
}

.contact-form-contact-info__container {
    position: relative;
    // &::after {
    //     @apply mx-auto lg:container; // these are the same classes applied to
    //     // the section it is bordering to ensure width, margins, etc. is the same
    //     content: '';
    //     width: 100%;
    //     height: 1px;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background-color: #{'rgb(var(--color-grey))'};
    // }
}