
// TailwindCSS
// @import "tailwindcss/base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities";
@tailwind base;
@tailwind components;
@tailwind utilities; 
 

// Letter spacing utility... 
@import "./custom-utilities.scss";
// Includes:
//  Adobe Font Definitions, downloaded font definitions
@import "./custom-base-styles.scss";
// Navigation, tabbing, accordions,
@import "./custom-components.scss";
// Overriding third party & theme styles
@import "./custom-overrides.scss";


// Accordion styles from accordion-js module
@import 'accordion-js/dist/accordion.min.css';
// Basic slideshow...
// @import "../../node_modules/tiny-slider/dist/tiny-slider.css"; 
// Fancyapps - Carousel and Fancybox enabled
@import "../../node_modules/@fancyapps/ui/dist/fancybox.css"; 
// Lite Youtube styles
// @import "../../node_modules/lite-youtube-embed/src/lite-yt-embed.css";
// NOTE: Lite Vimeo styles are built in via the inline element embed
// via <styles> element

//@import "../../node_modules/@fancyapps/ui/src/carousel.autoplay.css";


//---------------------------------------------
// Hero Banner 
//---------------------------------------------
@import "./page-sections/banner-section.scss";

// @import "./page-sections/tabbing-content-row.scss";

// //---------------------------------------------
// // Page Sections, Bloqs
// //---------------------------------------------
@import "./page-sections/features-row.scss";
@import "./page-sections/homepage-slideshow.scss";
@import "./page-sections/about-slideshow.scss";
@import "./page-sections/image-with-copy-row.scss"; 
@import "./page-sections/image-row.scss";
@import "./page-sections/cta-row.scss";


//@import "./google-locator/locator.css";

// //---------------------------------------------
// // Product page 
// //---------------------------------------------
@import "./products/product-optional-accessories.scss";
@import "./products/product-desc-spec-dl.scss";
@import "./products/product-intro-desc-and-slideshow.scss";

@import "./products/product-item-product-accessories-listings.scss";
@import "./products/product-category-listings.scss";
 
// //---------------------------------------------
// // Resources page 
// //---------------------------------------------
@import "./resources/resources-by-cat.scss";

// //---------------------------------------------
// // News Landing page 
// //---------------------------------------------
@import "./news/news-banner.scss";
@import "./news/news-and-events-listings.scss";
@import "./news/news-press-event-detail.scss";

//---------------------------------------------
// Accordions bloqs
//---------------------------------------------
@import "./page-sections/accordions.scss";


//---------------------------------------------
// About page
//---------------------------------------------
@import "./about/about.scss";

//---------------------------------------------
// Contact page
//---------------------------------------------
@import "./contact/contact-page.scss";

.carousel__dots .carousel__dot:after {
    width: 12px;
    height: 12px;
}

//---------------------------------------------
// Find a Dealer/Dealer Search page
//---------------------------------------------
@import "./find-a-dealer/find-a-dealer-page.scss";

@import "./layout/footer.scss";
@import "./layout/nav.scss"; 
