@mixin linkedImageHover() {
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover, &:active, &:focus, &:focus-within {
        transform: translateY(-3px);
    }
}
.event__list-container {
    .event__item {
        grid-template-rows: repeat(2, max-content);
        gap: 1rem;
        .event__info {
            grid-column: -1 / 1;
        }
        .event__img-container {
            @include linkedImageHover();
        }
        @screen sm {
            grid-template-columns: 1fr 1fr;
            .event__date {
                aspect-ratio: 1.65;
            }
        }

        @screen lg {
            grid-template-columns: 0.25fr auto;
            grid-gap: 50px;
            .event__info {
                grid-column: unset;
            }
            .event__date {
                align-self: start;
            }
        }
    }
}

.events-btn-link {
    @extend .spacing-100;
    transition-property: transform, color, opacity, height;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-duration: 300ms;
    grid-column: -1/1;
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #{'rgb(var(--color-body))'};
        margin-bottom: 1rem;
    }
}

//---------------------------------------------
// This applies to all 3 across 'features' of the News & Press
//  and Event entries so that all links change to brand's blue
//  to indicate hovering
//---------------------------------------------
.news-press__list-container {
    @screen lg {
        grid-template-columns: repeat(3, 1fr);
        img {
            aspect-ratio: 0.88;
        }
    }

    .news-press__item {
        a {
            transition-property: color;
            transition-duration: 300ms;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            &:hover, &:active, &:focus, &:focus-within {
                //color: #{'rgb(var(--color-brand))'};
            }
        }
        a:has(img) {
            overflow: hidden;
            transition-property: transform;
            transition-duration: 300ms;
            transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
            &:hover, &:active, &:focus, &:focus-within {
                //transform: scale(1.02);
            }
        }
    }
}