#about-banner {
    background-position: center center;
    background-size: cover;
}

#about_slideshow {
    .about-slide {
        img {
            transition: all 1s ease;
            height: auto;
            max-height: 500px;
        }
        .about-slide-content {
            opacity: 0;
            transition: all 0.5s ease 0.5s;
        }
        &.is-selected {
            img {
                //width: 100%;
                max-height: 300px;
            }
            .about-slide-content {
                opacity: 1;
                padding-bottom: 30px;
            }
        }
        
    }
    .carousel__dots {
        top: unset;
        bottom: 20px;
        li.carousel__dot {
            opacity: 1;
            &::after {
                opacity: 1;
                border: 1px solid white;
                background: transparent;
            }
            &.is-selected {
                
                &::after {
                    background: rgba(255, 255, 255, 0.55);
                }
            }
        }
    }
}