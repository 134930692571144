// Appending custom icons after each resource category type
// based on their CMS url-title
.resources-cats__list-container {
    .icon {
        height: 18px; // MAGIC#: See Resources page
        filter: $brandFilter;
        &-brochure, &-download {
            content: $brochure-icon-path;
        }
        &-image {
            content: url('/images/svgs-details/image-icon.svg');
        }
        &-legal {
            content: url('/images/svgs-details/legal-icon.svg');
        }
        &-mounting-instruction {
            content: url('/images/svgs-details/mounting-instruction-icon.svg');
            
        }
        &-operating-manual {
            content: url('/images/svgs-details/operating-manual-icon.svg');
            
        }
        &-spare-part {
            content: url('/images/svgs-details/spare-part-icon.svg');
        }
        &-video {
            content: $video-icon-path;
        }
    }
}

.resources-cat__list {
    li {
        @include resource-list-item-style();
        &:is(.video) {
            @include use-video-icon();
        }
    }
    @screen lg {
        grid-template-columns: repeat(var(--columns-count), 1fr);
        column-gap: 2.5rem;
        li {
            white-space: break-spaces;
        }
    }
}