.product__desc_spec_dl-container {
    @screen md {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;
    }
    @screen lg {
        column-gap: 0;
    }
}
.product__downloads-title, .product__specs-title {
    position: relative;
    &::after {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #{"rgb(var(--color-body))"};
    }
}
.product__downloads-list-container, .product__specs-list-container {
    column-gap: 2.5rem;
    row-gap: 2rem;
    @screen sm {
        grid-template-columns: repeat(2, 1fr);
    }
    @screen md {
        // See Stephanie Eckles guide: https://smolcss.dev/#smol-css-grid
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 15ch), 1fr));
    }
    .product__download-item {
        @include resource-list-item-style();
        &.video {
            @include use-video-icon();
        }
    }
    .product__download-link {
        // &:hover {
        //     img {
        //         transform: translateY(-2px);
        //     }
        // }
    }
}
