.addl-products-accessories__container, .product-category__list-container {
    gap: 50px;
    @screen md {
        grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
    }
    // @screen lg {
    //     grid-template-columns: repeat(auto-fill, minmax(min(325px, 100%), 1fr))
    // }
    .addl-product-accessory-item, .product-item {
        img {
            aspect-ratio: 1.1;
        }
        .addl-product-accessory-item__content, .product-item__content {
            //@extend %brand-gradient;
            z-index: 1;
            &::before {
                //@extend %brand-to-body-gradient;
                background-color: rgba(36,31,33,0.85);
                content: '';
                position: absolute;
                inset: 0;
                opacity: 0;
                z-index: -1;
                transition: opacity 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            &:hover {
               &::before {
                opacity: 1;
               } 
            }
        }
    }
}