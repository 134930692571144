footer {
    #footer__content {
        #footer__content-top {
            @screen lg {
                grid-template-columns: 1fr 1fr;
            }
            #footer__copyright-social-contact {
                @screen lg {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
        #footer__content-bottom {
            @screen lg {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}