.products_accessories__section {
    background-color: #{"rgba(var(--color-neutral), 0.52)"};
    .products__accessories-title {
        grid-template-columns: repeat(2, max-content);
        @extend .spacing-100;
        // &::before {
        //     content: '';
        //     position: relative;
        //     width: 50px;
        //     height: 1px;
        //     background-color: #{"rgb(var(--color-brand))"};
        // }
    }
    .products__accessories-content-container {
        .products__accessories-container {
            @screen md {
                grid-template-columns: repeat(2, minmax(min(100%, 250px), 1fr));
            }
            @screen lg {
                grid-template-columns: repeat(3, minmax(min(100%, 300px), 1fr));
            }
            [data-block-name="product_accessory"] {
                .product_accessory__image {
                    //aspect-ratio: 1 / 1;
                    @screen md {
                        min-height: 250px;
                    }
                    @screen lg {
                        min-height: 300px;
                    }
                }
                // .product_accessory__channel a {
                //     transition-property: text-decoration;
                //     transition-duration: 300ms;
                //     transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
                //     &:hover, &:active, &:focus, &:focus-within {
                //         text-decoration: underline;
                //     }
                // }
            }
        }
    }
}