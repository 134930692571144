@screen lg {
    .use-img-width {
        & > picture {
            display: block;
            width: max-content;
        }
    }
}

@screen lg {
    [data-block-name="image_with_copy_row"],
    [data-block-name="two_images_with_copy_row"] {
        --offset-image-height: 300px;
        --min-image-copy-row-img-height: 500px;
    }
}
@screen xl {
    [data-block-name="image_with_copy_row"],
    [data-block-name="two_images_with_copy_row"] {
        --offset-image-height: 350px;
        --min-image-copy-row-img-height: 650px;
    }
}
@screen 2xl {
    [data-block-name="image_with_copy_row"],
    [data-block-name="two_images_with_copy_row"] {
        --offset-image-height: 400px;
        --min-image-copy-row-img-height: 750px;
    }
}

@screen lg {
    [data-block-name="image_with_copy_row"],
    [data-block-name="two_images_with_copy_row"] {
        & > div {
            [data-block-name="image_item"]:nth-child(1),
            [data-block-name="image_item"]:nth-child(2) {
                min-height: var(--min-image-copy-row-img-height);
                height: 100%;
            }
        }
    }
    [data-block-name="two_images_with_copy_row"] {
        & > div {
            grid-template-columns: repeat(2, 1fr);
            // So the 3rd item in the block row, the 2nd image item:
            div:nth-child(3)[data-block-name="image_item"] {
                height: var(--offset-image-height);
                margin-top: calc(var(--offset-image-height) * -0.5);
                position: relative;
            }
        }
    }
}

@screen xl {
    [data-block-name="image_with_copy_row"],
    [data-block-name="two_images_with_copy_row"] {
        & > div {
            div:nth-child(1)[data-block-name="copy_item"] {
                // MAGIC#: 
                margin-top: calc(var(--offset-image-height) * 0.25);
            }
            // So the 3rd item in the block row, the 2nd image item:
            div:nth-child(3)[data-block-name="image_item"] {
                height: var(--offset-image-height);
                margin-top: calc(var(--offset-image-height) * -0.25);
                position: relative;
            }
            [data-block-name="image_item"]:nth-child(1),
            [data-block-name="image_item"]:nth-child(2) {
                min-height: var(--min-image-copy-row-img-height);
            }
        }
    }
}