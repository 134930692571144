.timeline-event {
    &:not(:last-of-type) {
        .dot {
            &::after {
                content: " ";
                position: absolute;
                width: 2px;
                height: 100%;
                top: 6px;
                z-index: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: #009BE9;
            }
        }
    }

    .dot {
        text-align: center;
        position: relative;

        svg {
            position: relative;
            z-index: 2;
        }


    }
}