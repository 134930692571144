[data-block-name="image_row"] {
    &::before {
        content: '';
        position: absolute; 
        height: 50%; 
        width: 100%;
        bottom: 50%;
        background-color: #{'rgba(var(--color-neutral) / 0.52)'};
        z-index: -1;
    }
}