//---------------------------------------------
// KEYFRAME ANIMATIONS
// -Utilized in the modal dialog transitions, 
// cookie (notice message) transition on close
//---------------------------------------------
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slidein {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes slideoutheight {
  from {
    max-height: 15rem;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

// ---------------------------------------------
// Cookie consent message
// ---------------------------------------------
#notice-message {
  display: none;
  &.open {
    opacity: 1;
    display: block;
  }
  &.close {
    animation: slideoutheight 0.5s forwards;
  }
  .container {
    grid-template-columns: 1fr auto 1fr;
    p:nth-child(1) {
      grid-column-start: 2;
    }
    button {
      margin-left: auto;
    }
  }
}

//---------------------------------------------
// Cookie consent form
//---------------------------------------------
#cookieConsentForm {
  &.close {
    animation: slideoutheight 0.5s forwards;
  }
}

//---------------------------------------------
// Freeform Form OVERRIDES of exp:freeform_form:render styles
// that are auto-included with those template tags
//---------------------------------------------
form {
  .freeform-row {
    margin: 0;
    display: grid;
    @screen lg {
      grid-template-columns: repeat(2, 0.5fr);
    }
    gap: 2rem;
    .freeform-column {
      padding: 0;
      float: none;
      width: unset;
      .freeform-label {
        font-weight: normal;
        &.freeform-required {
          &::after {
            // @apply text-red-500;
            content: none;
          }
        }
      }
      .freeform-input {
        border-bottom: 1px solid;
        min-height: 50px;
        &::placeholder {
          color: #{'rgb(var(--color-body))'};
          opacity: 1;
        }
      }
    }
    .freeform-column-12 {
      grid-column: -1 / 1;
    }
  }
}

//---------------------------------------------
// Prevent body/page scrolling when overlay menu is opened;
// overflow hidden works on desktops, but iOS doesn't behave
//---------------------------------------------
.noscroll {
  overflow: hidden;
  position: fixed;
  inset-inline: 0;
}