//---------------------------------------------
// IMPORTANT: Anything placed in a TailwindCSS @utilities
// layer will have responsive variants generated for it automatically
//---------------------------------------------

@mixin letter-spacing($spacing) {
  letter-spacing: calc($spacing / 1000) * 1em;
}
.spacing-120 {
  @include letter-spacing(120);
}
.spacing-100 {
  @include letter-spacing(100);
}
.-spacing-20 {
  @include letter-spacing(-20);
}

//---------------------------------------------
// Used in various spots for Products, Accessories pages
//---------------------------------------------
%brand-to-body-gradient {
  background-blend-mode: multiply;
  background: linear-gradient(180deg, rgba(108,105,106,0.6) 0%, rgba(36,31,33,0.6) 100%), linear-gradient(180deg, rgba(0,155,223,1) 0%, rgba(0,78,112,1) 100%);
}
%body-gradient {
  background-blend-mode: multiply;
  background: linear-gradient(180deg, rgba(108,105,106,0.6) 0%, rgba(36,31,33,0.6) 100%);
}
%brand-gradient {
  background-blend-mode: multiply;
  background: linear-gradient(180deg, rgba(0,155,223,1) 0%, rgba(0,78,112,1) 100%);
}


//---------------------------------------------
// Used on Resources page, Product Downloads list items
//---------------------------------------------
@mixin resource-list-item-style() {
  will-change: contents;
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: top 4px left 0;
  padding: 0 0 0 1.5rem; //MAGIC#: see /resources page
  background-image: $brochure-icon-path;
  list-style: none;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
  &:hover, &:focus-visible, &:focus, &:active {
    background-position: top 2px left 0;
    * {
      color: rgb(var(--color-brand));
    }
  }
}

//---------------------------------------------
// Video icon for list item
// - overriding the above ^ module 
//---------------------------------------------
@mixin use-video-icon($position: left 0 top 4px) {
  background-image: $video-icon-path;
  background-size: 1rem;
  background-position: $position;
  background-repeat: no-repeat;
  &:hover, &:focus-visible, &:focus, &:active {
    background-position: top 2px left 0;
    * {
      color: rgb(var(--color-brand));
    }
  }
}

